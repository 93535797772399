import React from 'react'

const numbers = {
  context: 'Why Us',
  title: '5 Reasons To Choose Cam-Air',
  description:
    'There are many cooling and heating providers in the Rio Grande Valley. Here are five reasons we stand above the rest:',
  list: [
    {
      name: 'We are a one stop shop.',
      description:
        'When you call us up you know we will be able to get the job done - there is no need to look elsewhere. We service all major systems for your home or business.',
    },
    {
      name: 'We provide exceptional service.',
      description:
        'Our reviews speak for themselves. We do not joke around when it comes to making sure you and your HVAC system are happy.',
    },
    {
      name: 'We stand behind our work with a warranty on all repairs.',
      description:
        'We have been serving South Texas for many years, so you can trust we will be available when you need us.',
    },
    {
      name: 'We do not subcontract our work.',
      description:
        'The technician we send to you will be one of our employees. We will only send someone to perform service on our behalf that upholds our values.',
    },
    {
      name: 'We’ll get the job done without the hassle.',
      description:
        'When our customers are happy, we are happy - it is that simple. We will not give you the runaround or overcharge you.',
    },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Numbers = () => {
  return (
    <section className="px-4 py-12 mx-auto text-center bg-white lg:py-16 md:px-12 lg:max-w-5xl">
      <h2 className="font-semibold tracking-wider uppercase text-primary">{numbers.context}</h2>
      <h3 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 md:text-5xl">{numbers.title}</h3>
      <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">{numbers.description}</p>
      <ol className="mt-12">
        {numbers.list.map((item, index) => (
          <li key={item.name} className={classNames(index !== numbers.list.length - 1 ? 'pb-10' : '', 'relative')}>
            {
              <>
                {index !== numbers.list.length - 1 ? (
                  <div
                    className="absolute h-full border-l border-dashed top-4 left-6 border-primary"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex items-start text-left group">
                  <span className="flex items-center h-9">
                    <span className="relative z-10 flex items-center justify-center w-12 h-12 text-2xl text-white rounded-full bg-primary">
                      {index + 1}
                    </span>
                  </span>
                  <span className="flex flex-col items-start min-w-0 ml-4 md:ml-8">
                    <span className="mb-2 text-xl font-semibold">{item.name}</span>
                    <span className="text-gray-500">{item.description}</span>
                  </span>
                </div>
              </>
            }
          </li>
        ))}
      </ol>
    </section>
  )
}

export default Numbers
