import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/home/hero'
import Services from '../components/home/services'
import Testimonials from '../components/home/testimonials'
import Numbers from '../components/home/numbers'

const IndexPage = () => (
  <Layout>
    <Seo title="The Rio Grande Valley's leading AC cooling and heating experts" />
    <Hero />
    <Services />
    <Testimonials />
    <Numbers />
  </Layout>
)

export default IndexPage
