import React from 'react'
import { Link } from 'gatsby'

import { HomeIcon, OfficeBuildingIcon } from '@heroicons/react/outline'

const services = {
  context: 'Service Simplified',
  title: 'Cooling and Heating All South Texas',
  description:
    'We offer a broad range of dependable HVAC related services for projects of all sizes, and are proud of the team that stands behind them.',
  list: [
    {
      name: 'Residential',
      href: '/residential',
      icon: HomeIcon,
      description:
        'We will diagnose your system issues and determine the most comfortable solution for your South Texas home.',
    },
    {
      name: 'Commercial',
      href: '/commercial',
      icon: OfficeBuildingIcon,
      description:
        'No matter the size of your business or the size of your system, the experts at Cam-Air handle everything for you.',
    },
    {
      name: 'AC Repair',
      icon: props => (
        <svg fill="currentColor" viewBox="-50 -50 612 612" {...props}>
          <path
            fill="currentColor"
            d="M501.1 395.7L384 278.6c-23.1-23.1-57.6-27.6-85.4-13.9L192 158.1V96L64 0 0 64l96 128h62.1l106.6 106.6c-13.6 27.8-9.2 62.3 13.9 85.4l117.1 117.1c14.6 14.6 38.2 14.6 52.7 0l52.7-52.7c14.5-14.6 14.5-38.2 0-52.7zM331.7 225c28.3 0 54.9 11 74.9 31l19.4 19.4c15.8-6.9 30.8-16.5 43.8-29.5 37.1-37.1 49.7-89.3 37.9-136.7-2.2-9-13.5-12.1-20.1-5.5l-74.4 74.4-67.9-11.3L334 98.9l74.4-74.4c6.6-6.6 3.4-17.9-5.7-20.2-47.4-11.7-99.6.9-136.6 37.9-28.5 28.5-41.9 66.1-41.2 103.6l82.1 82.1c8.1-1.9 16.5-2.9 24.7-2.9zm-103.9 82l-56.7-56.7L18.7 402.8c-25 25-25 65.5 0 90.5s65.5 25 90.5 0l123.6-123.6c-7.6-19.9-9.9-41.6-5-62.7zM64 472c-13.2 0-24-10.8-24-24 0-13.3 10.7-24 24-24s24 10.7 24 24c0 13.2-10.7 24-24 24z"
          ></path>
        </svg>
      ),
      description:
        'We are proud to employ dedicated, skilled AC repair technicians who are available 7 days a week. Plus, we stand behind our work with an industry best warranty.',
    },
    {
      name: 'AC Installation',
      icon: props => (
        <svg fill="currentColor" viewBox="-25 -35 626 562" {...props}>
          <path
            fill="currentColor"
            d="M544,0H32A32,32,0,0,0,0,32V192a32,32,0,0,0,32,32H544a32,32,0,0,0,32-32V32A32,32,0,0,0,544,0ZM512,160H64V128H512ZM216,424a40,40,0,0,1-40,40h-6.22c-20,0-38.18-13.88-41.28-33.61-2.88-18.41,6.92-34.22,21.84-41.47,5.73-2.78,9.66-8.25,9.66-14.63V356.67a16.1,16.1,0,0,0-21.42-15.17A87.55,87.55,0,0,0,80.7,435.24C86.26,479.82,126.55,512,171.47,512H176a88,88,0,0,0,88-88V256H216ZM437.42,309.5A16.1,16.1,0,0,0,416,324.67v17.62c0,6.38,3.93,11.85,9.66,14.63,14.92,7.25,24.72,23.06,21.84,41.47C444.4,418.12,426.2,432,406.22,432H400a40,40,0,0,1-40-40V256H312V392a88,88,0,0,0,88,88h4.53c44.92,0,85.21-32.18,90.77-76.76A87.55,87.55,0,0,0,437.42,309.5Z"
          ></path>
        </svg>
      ),
      description:
        'Our team is experienced with replacing systems of all types and sizes. We provide on-site estimates and high quality installations in the Rio Grande Valley area.',
    },
  ],
}

const Services = () => (
  <section className="relative px-4 py-12 bg-white md:px-12 lg:py-16">
    <div className="mx-auto text-center lg:max-w-5xl">
      <h2 className="text-base font-semibold tracking-wider uppercase text-primary">{services.context}</h2>
      <h3 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 md:text-5xl">{services.title}</h3>
      <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">{services.description}</p>
      <div className="mt-12">
        <div className="grid w-full grid-cols-1 gap-8 md:grid-cols-2">
          {services.list.map(item =>
            item.href ? (
              <Link to={item.href} key={item.name} className="pt-6">
                <div className="flow-root px-6 pb-8 rounded-lg bg-gray-50">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-1 rounded-md shadow-sm bg-secondary">
                        <item.icon className="w-10 h-10 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-2xl font-medium tracking-tight text-gray-900">{item.name}</h3>
                    <p className="mt-5 text-gray-500">{item.description}</p>
                  </div>
                </div>
              </Link>
            ) : (
              <div to={item.href} key={item.name} className="pt-6">
                <div className="flow-root px-6 pb-8 rounded-lg bg-gray-50">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-1 rounded-md shadow-sm bg-secondary">
                        <item.icon className="w-10 h-10 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-2xl font-medium tracking-tight text-gray-900">{item.name}</h3>
                    <p className="mt-5 text-gray-500">{item.description}</p>
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  </section>
)

export default Services
