import React from 'react'

const testimonials = [
  {
    review:
      'I was very impressed with the fact that they were able to send someone out right away, they were very informative with what the problem was and worked with me to make the price affordable for my budget. I would highly recommend them and am looking forward to doing more business with them in the near future!',
    href: 'https://www.google.com/maps/contrib/111168541667652173492/place/ChIJl_8tiGt5ZYYRl--RUuhKsVE/@26.9790468,-98.1864884,8z/data=!4m6!1m5!8m4!1e1!2s111168541667652173492!3m1!1e1?hl=en-US',
    img: 'https://lh3.googleusercontent.com/a-/AOh14GiR_NQ_eKMvYPPpuXv4bAeoJGJc2yHp5e9BUoXeDl0=w36-h36-p-rp-mo-br100',
    alt: 'google reviewer Charlie Henderson',
    name: 'Charlie Henderson',
  },
  {
    review:
      'Guys were super friendly to work with, honest about everything that needed to be done & how it needed to be done, and price couldn’t be beat. Was happy that work came with a one year warranty and got completed in a timely manner. Would highly recommend.',
    href: 'https://www.google.com/maps/contrib/110815056443431927668/place/ChIJl_8tiGt5ZYYRl--RUuhKsVE/@31.9406544,-108.5602559,6z/data=!4m6!1m5!8m4!1e1!2s110815056443431927668!3m1!1e1?hl=en-US',
    img: null,
    alt: 'google reviewer DEADMAN YAKKIN',
    name: 'DEADMAN YAKKIN',
  },
]

const Testimonials = () => {
  return (
    <section className="bg-secondary">
      <div className="max-w-5xl px-8 pb-6 mx-auto space-y-8 md:space-x-10 md:space-y-0 md:grid md:grid-cols-2 md:px-10 lg:px-18">
        {testimonials.map(item => (
          <a
            key={item.name}
            href={item.href}
            className="px-4 py-4 md:flex md:flex-col"
            target="_blank"
            rel="noreferrer"
          >
            <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
              <div className="relative text-lg text-white md:flex-grow">
                <svg
                  className="absolute top-0 left-0 w-8 h-8 transform -translate-x-3 -translate-y-2 text-primary"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="relative">{item.review}</p>
              </div>
              <footer className="mt-8">
                <div className="flex items-start">
                  <div className="inline-flex flex-shrink-0 border-2 border-white rounded-full">
                    {item.img ? (
                      <img className="w-12 h-12 rounded-full" src={item.img} alt={item.alt} />
                    ) : (
                      <span className="flex items-center justify-center w-12 h-12 text-2xl text-white bg-[#AB47BC] rounded-full">
                        {item.name.substring(0, 1)}
                      </span>
                    )}
                  </div>
                  <div className="ml-4">
                    <div className="text-base font-medium text-white">{item.name}</div>
                    <div className="text-base font-medium text-indigo-200">Google Review</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </a>
        ))}
      </div>
    </section>
  )
}

export default Testimonials
