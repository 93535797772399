import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const hero = {
  title: 'AC Services for\nResidents and Businesses',
  subtitle: 'in the Rio Grande Valley',
  cta: 'Request Service',
}

const Hero = () => {
  return (
    <section className="relative pb-12 lg:pb-16">
      <div className="absolute inset-x-0 bottom-0 bg-gray-50 h-1/2" />
      <div className="relative shadow-xl sm:overflow-hidden">
        <div className="absolute inset-0">
          <StaticImage
            className="object-cover w-full h-full"
            src="../../images/ac-with-gauges.png"
            width={500}
            quality={95}
            formats={['auto', 'webp', 'avif']}
            alt="ac with gauges"
            placeholder="none"
          />
          <div className="absolute inset-0 bg-secondary mix-blend-multiply" />
        </div>
        <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
          <h1 className="m-auto text-4xl font-bold text-center sm:text-5xl lg:text-6xl">
            <span className="block mb-4 text-white whitespace-pre-wrap">{hero.title}</span>
            <span className="block text-4xl font-thin text-white">{hero.subtitle}</span>
          </h1>
          <p className="max-w-lg mx-auto mt-6 text-xl text-center text-indigo-200 sm:max-w-3xl">{hero.description}</p>
          <div className="max-w-sm mx-auto mt-10 sm:max-w-none sm:flex sm:justify-center">
            <Link
              to="/contact"
              className="flex items-center justify-center px-4 py-3 text-xl font-medium text-white border border-transparent rounded-md shadow-sm bg-primary lg:text-2xl hover:bg-secondary sm:px-8"
            >
              {hero.cta}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
